const Config = {
  datatableFeatures: {
    extraButtons: [
    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Payout',
      createButtonRoute: 'administration-payout-new',
      refresh: true,
    },
    tableActionField: false,
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'merchant_id', text: 'Merchant ID' },
    { value: 'merchant_name', text: 'Merchant Name' },
    { value: 'payment_provider', text: 'Payment Provider' },
    { value: 'bank_account_number', text: 'Account No' },
    { value: 'status', text: 'Status' },
  ],
  tableData() {
    return {
      tableAction: [
        // CUSTOM
        // {
        //   enable: true, param: 'id', btnLabel: 'Disburse', action: 'custom', btnIcon: '', btnOnly: false, variant: 'primary', size: 'sm', iconSize: '12', actionParam: 'disburseSettlement', disableCondition: 'status:COMPLETED',
        // },
        // {
        //   enable: true, param: 'id', btnLabel: 'Update Status', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'openUpdatePayoutStatus',
        // },

        // DEFAULT
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        // {
        //   "updated_at": "2023-12-28T16:09:52",
        //   "published": null,
        // }
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        // {
        //   "settlement_id": 5814,
        //   "transaction_date": "2024-01-16",
        //   "adjustment_type": "SUBTRACTION",
        //   "initial_settlement_value": 2519,
        //   "adjustment_by": "wira",
        //   "approve_by": "azrul",
        //   "created_at": "2024-01-17T18:01:10",
        //   "reason": "Extra Payment",
        //   "id": 2,
        //   "transaction_invoice": "FPX1701675164afMBNQzv",
        //   "adjustment_value": 19,
        //   "current_settlement_value": 2500,
        //   "record_status": 1,
        //   "updated_at": "2024-01-17T18:01:10"
        // }
        {
          key: 'settlement_id', label: 'Settlement ID', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'transaction_invoice', label: 'TXN Invoice', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'adjustment_type', label: 'Type', sortable: true, visible: true, type: 'string', showInDetails: false,
        },
        {
          key: 'initial_settlement_value', label: 'Initial Value', sortable: true, visible: true, type: 'monetary', showInDetails: false,
        },
        {
          key: 'adjustment_value', label: 'Adjustment Value', sortable: true, visible: true, type: 'monetary', showInDetails: false,
        },
        {
          key: 'current_settlement_value', label: 'Current Settlement Value', sortable: true, visible: true, type: 'monetary', showInDetails: false,
        },
        {
          key: 'adjustment_by', label: 'Adj By', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'approve_by', label: 'Approved By', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'reason', label: 'Reason', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        // {
        //   key: 'bank_name', label: 'Bank Name', sortable: true, visible: true, type: 'string',
        // },
        // {
        //   key: 'bank_account_number', label: 'Account No', sortable: true, visible: true, type: 'string',
        // },
        // {
        //   key: 'total_revenue', label: 'Amount', sortable: true, visible: true, type: 'monetary',
        // },
        // {
        //   key: 'payment_provider', label: 'Payment Provider', sortable: true, visible: true, type: 'underscore_string',
        // },
        // {
        //   key: 'total_sale', label: 'sales', sortable: true, visible: false, type: 'monetary', showInDetails: true,
        // },
        // {
        //   key: 'status', label: 'Status', sortable: true, visible: true, type: 'settlement_status',
        // },
        //
        // {
        //   key: 'total_outstanding_amount', label: 'Outstanding', sortable: true, visible: false, type: 'monetary', showInDetails: true,
        // },
        // {
        //   key: 'name', label: 'Name', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'report_id', label: 'Report ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'settlement_generated_date', label: 'Generated Date', sortable: true, visible: false, type: 'date', showInDetails: true,
        // },
        // {
        //   key: 'settlement_report_date', label: 'Report Date', sortable: true, visible: false, type: 'date', showInDetails: true,
        // },
        // {
        //   key: 'threshold_invoice_list', label: 'Threshold Invoice List', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'payout_transaction_no', label: 'Payout TXN #', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'external_settlement_id', label: 'External Settlement ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        // {
        //   key: 'response', label: 'Response', sortable: true, visible: false, type: 'string', showInDetails: true,
        // },
        //
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, type: 'date',
        },
        // {
        //   key: 'created_at', label: 'Created At', sortable: true, visible: false, type: 'date', showInDetails: true,
        // },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: false,
        },
      ],
    }
  },
}

export default Config
