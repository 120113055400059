const Config = {
  datatableFeatures: {
    extraButtons: [
      {
        buttonLabel: 'Upload Settlement',
        buttonRef: 'uploadSettlementBtn',
        variant: 'info',
      },
    ],
    tableStatusFilterButton: [
      {
        buttonLabel: 'Pending',
        buttonRef: 'pendingSettlementFilter',
        active: true,
      },
      {
        buttonLabel: 'Completed',
        buttonRef: 'completedSettlementFilter',
        active: false,
      },
    ],
    tablePaymentProviderFilterButton: [
      {
        // SWITCH_WEBPAY
        buttonLabel: 'All',
        buttonRef: 'paymentProviderFilterNone',
        active: false,
      },
      {
        // SWITCH_WEBPAY
        buttonLabel: 'FPX',
        buttonRef: 'paymentProviderFilterWebpay',
        active: true,
      },
      {
        // SWITCH_BNPL
        buttonLabel: 'BNPL',
        buttonRef: 'paymentProviderFilterBNPL',
        active: false,
      },
      {
        // SWITCH_CC
        buttonLabel: 'Credit Card',
        buttonRef: 'paymentProviderFilterCC',
        active: false,
      },
      {
        // SWITCH_STRIPE_CC
        buttonLabel: 'Stripe',
        buttonRef: 'paymentProviderFilterStripe',
        active: false,
      },
      {
        // SWITCH_EWALLET
        buttonLabel: 'BoldPay E-wallet',
        buttonRef: 'paymentProviderFilterCwallet',
        active: false,
      },
      {
        // SWITCH_EWALLET
        buttonLabel: 'SenangPay E-wallet',
        buttonRef: 'paymentProviderFilterEwallet',
        active: false,
      },
      {
        // SWITCH_CRYPTOCURRENCY
        buttonLabel: 'Cryptocurrency',
        buttonRef: 'paymentProviderFilterCrypto',
        active: false,
      },
      {
        // SWITCH_PAYPAL
        buttonLabel: 'Paypal',
        buttonRef: 'paymentProviderFilterPaypal',
        active: false,
      },
      {
        // SWITCH_STRIPE_CC_EMBED
        buttonLabel: 'CC Embed',
        buttonRef: 'paymentProviderFilterCCEmbed',
        active: false,
      },

    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Payout',
      createButtonRoute: 'administration-payout-new',
      refresh: true,
    },
    tableActionField: false,
    datepicker: true,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'merchant_id', text: 'Merchant ID' },
    { value: 'merchant_name', text: 'Merchant Name' },
    { value: 'payment_provider', text: 'Payment Provider' },
    { value: 'bank_account_number', text: 'Account No' },
    { value: 'status', text: 'Status' },
  ],
  tableData() {
    return {
      tableAction: [
        // CUSTOM
        {
          enable: true, param: 'id', btnLabel: 'Adjust', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'adjustSettlement', disableCondition: 'status:COMPLETED',
        },
        {
          enable: true, param: 'id', btnLabel: 'Disburse', action: 'custom', btnIcon: '', btnOnly: false, variant: 'primary', size: 'sm', iconSize: '12', actionParam: 'disburseSettlement', disableCondition: 'status:COMPLETED',
        },
        {
          enable: true, param: 'settlement_uuid', btnLabel: 'Reconcile', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'reconcileSettlement', disableCondition: 'status:NOTHING',
        },
        {
          enable: true, param: 'settlement_uuid', btnLabel: 'Adjust', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'viewDetails', disableCondition: 'status:NOTHING',
        },
        // {
        //   enable: true, param: 'settlement_uuid', btnLabel: '', action: 'custom', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'viewDetails',
        // },
        // {
        //   enable: true, param: 'id', btnLabel: 'Update Status', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'openUpdatePayoutStatus',
        // },

        // DEFAULT
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        // {
        //   "updated_at": "2023-12-28T16:09:52",
        //   "published": null,
        // }
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'id', label: 'ID', sortable: true, visible: false,
        },
        {
          key: 'merchant_id', label: 'Merchant ID', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'merchant_name', label: 'Merchant Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'bank_name', label: 'Bank Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'bank_account_number', label: 'Account No', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'total_revenue', label: 'Revenue', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'payment_provider', label: 'Payment Provider', sortable: true, visible: true, type: 'underscore_string',
        },
        {
          key: 'total_sale', label: 'Sales', sortable: true, visible: false, type: 'monetary', showInDetails: true,
        },
        {
          key: 'status', label: 'Status', sortable: true, visible: true, type: 'settlement_status',
        },

        {
          key: 'total_outstanding_amount', label: 'Outstanding Amount', sortable: true, visible: false, type: 'monetary', showInDetails: true,
        },
        {
          key: 'settlement_channel', label: 'Settlement Channel', sortable: false, visible: true, type: 'settlementChannel', showInDetails: true,
        },
        {
          key: 'invoice_list', label: 'Invoice List', sortable: false, visible: false, showInDetails: true,
        },
        {
          key: 'name', label: 'Name', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'report_id', label: 'Report ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'settlement_generated_date', label: 'Generated Date', sortable: true, visible: false, type: 'date', showInDetails: false,
        },
        {
          key: 'transaction_date', label: 'Transaction Date', sortable: true, visible: false, type: 'date', showInDetails: true,
        },
        {
          key: 'settlement_report_date', label: 'Generated Date', sortable: true, visible: false, type: 'date', showInDetails: true,
        },
        {
          key: 'threshold_invoice_list', label: 'Threshold Invoice List', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'payout_transaction_no', label: 'Payout TXN #', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'settlement_uuid', label: 'Settlement UUID', sortable: true, visible: false, type: 'settlementUuid', showInDetails: true,
        },
        {
          key: 'external_settlement_id', label: 'External Settlement ID', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'response', label: 'Response', sortable: true, visible: false, type: 'string', showInDetails: true,
        },

        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false, type: 'date', showInDetails: true,
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
